







































import { Component, Vue } from 'vue-property-decorator'
import { isIOS } from '@/common/utils'
import {
  callAppFunc_setNavColor,
  callAppFunc_setTitleFontColor,
  callAppFunc_setNavCloseIcon,
  callAppFunc_setNavBackIcon,
  callAppFunc_GoWallet
} from '@/common/utils/bridge'
@Component({})
export default class extends Vue {
  goWallet() {
    if (isIOS) {
      callAppFunc_GoWallet()
    } else {
      this.$router.push('/wallet')
    }
  }
  created() {
    callAppFunc_setNavColor({
      navColor: '#5C3BEB'
    })
    callAppFunc_setTitleFontColor({
      navFontColor: '#ffffff'
    })
    callAppFunc_setNavBackIcon({
      backIconUrl: 'https://qiniu.kigo.top/h5resource/icon_22_back_1%402x.png'
    })
    callAppFunc_setNavCloseIcon({
      closeIconUrl:
        'https://qiniu.kigo.top/h5resource/icon_22_close_white_2%402x.png'
    })
  }
  destroyed() {
    callAppFunc_setNavColor({
      navColor: '#ffffff'
    })
    callAppFunc_setTitleFontColor({
      navFontColor: '#000000'
    })
    callAppFunc_setNavBackIcon({
      backIconUrl:
        'https://qiniu.kigo.top/h5resource/icon_22_back_black%402x.png'
    })
    callAppFunc_setNavCloseIcon({
      closeIconUrl:
        'https://qiniu.kigo.top/h5resource/icon_22_close_black_2%402x.png'
    })
  }
}
